import React, { Component } from 'react';
import Projectfull from "../../components/Projectfull";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import biocheckfullImg from "../../images/projectsfull/biocheckfull.jpg";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

class Projects extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Biocheck.UGent</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page project">
          <Header />
          <Projectfull
              projectfullname="Biocheck.UGent"
              projectfulldescription="With EntityOne we created a web tool for the UGent faculty of veterinary medicine. This web tool is a scientific risk-based and independent scoring system to evaluate the quality of on-farm biosecurity. I was responsible for the design of the website and the front-end development."
              projectclient="UGent"
              projectrole="Web Design & Front-end Development"
              projectdate="2019 - 2020"
              projectfullimg={<div className="project--img--inner"><img data-src={biocheckfullImg} className="project--img lazyload" alt="Biocheck.UGent" /></div>}
              projectnextlink="/projects/zorgband"
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Projects;